// 新增班级的弹框
<template>
  <div class="add_teacher">
    <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width">
      <template v-slot:content>
        <div class="real_content">
          <!-- 加载框 -->
          <Loading v-if="showLoading"></Loading>
          <!-- 内容 -->
          <div class="add_teacher_dialog_content" v-else>
            <!-- 列表布局 -->
            <div class="course_list">
              <!-- 班级名称 -->
              <div class="margin_top_10">
                <div>
                  <span class="red_icon">*</span>
                  <span>班级名称：</span>
                  <span>
                    <el-input class="name_input" clearable v-model="className" placeholder="请输入班级名称" maxlength="20" size="small"></el-input>
                  </span>
                </div>
              </div>
              <!-- 提示 -->
              <div class="margin_top_10">
                <span class="tips">注：班级类型保存后不可修改</span>
              </div>

              <!-- 班级类型 -->
              <div class="margin_top_14">
                <div>
                  <span class="red_icon">*</span>
                  <span>班级类型：</span>
                  <span class="margin_left_20">
                    <span
                      v-show="isShowTryClass"
                      :class="{
                        item_selected: modeType == 0,
                        item_unselected: modeType == 1,
                      }"
                      @click.stop="selectType(0)"
                    >
                      试听课班级
                    </span>

                    <span
                      :class="{
                        item_selected: modeType == 1,
                        item_unselected: modeType == 0,
                      }"
                      @click.stop="selectType(1)"
                    >
                      正式课班级
                    </span>
                  </span>
                </div>
              </div>

              <!-- 课程访问权限 -->
              <div class="item margin_top_20">
                <div>
                  <span class="red_icon">*</span>
                  <span>课程访问权限：</span>

                  <div class="select_list">
                    <div class="item" v-for="(item, index) in CourseList" :key="index">
                      <span
                        :class="{
                          selected: CourseList[index].state == 1,
                          unselected: CourseList[index].state == 0,
                          noneselect: CourseList[index].state == -1,
                        }"
                        @click.stop="selectCourse(index)"
                      >
                        {{ item.subName }}
                      </span>

                      <span v-for="(item, k) in CourseList[index].levelList" :key="k">
                        <span
                          :class="{
                            item_selected: CourseList[index].levelList[k].state == 1,
                            item_unselected: CourseList[index].levelList[k].state == 0,
                            item_noneselect: CourseList[index].levelList[k].state == -1,
                          }"
                          @click="selectCourseItem(index, k)"
                          >{{ item.levelName }}</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 提示 -->
              <!-- <div>
                <span class="tips">注：班级类型保存后不可修改</span>
              </div> -->

              <!-- 选择老师 -->
              <div class="margin_top_10">
                <div>
                  <span class="red_icon">*</span>
                  <span>选择老师 ： </span>
                  <span>
                    <el-select class="name_input" v-model="teacherName" size="small" placeholder="请选择">
                      <el-option v-for="item in teacherlist" :key="item.teacher_id" :label="item.teacher_name" :value="item.teacher_id"> </el-option>
                    </el-select>
                  </span>
                </div>
              </div>
              <!-- 预招生人数 -->
              <div class="margin_top_10">
                <div>
                  <span>预招生人数：</span>
                  <span>
                    <el-input
                      class="name_input"
                      clearable
                      v-model.number="classNumber"
                      placeholder="必须为整数"
                      maxlength="20"
                      size="small"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                    ></el-input>
                  </span>
                </div>
              </div>

              <!-- 是否限制人数 -->
              <div class="margin_top_10">
                <div>
                  <span> 限制人数：</span>
                  <span class="margin_left_40">
                    <el-switch v-model="isLimPeople"> </el-switch>
                  </span>
                  <span class="margin_left_10">开启后入班人数不可超过预招人数</span>
                </div>
              </div>
            </div>
            <!-- 底部的取消和确定按钮 -->
            <div class="bom_action">
              <!-- 取消 -->
              <div class="cancel" @click.stop="close"><span>取</span><span style="margin-left: 16px">消</span></div>
              <!-- 确定 -->
              <el-button :loading="showCommitLoading" :class="['commit']" @click.stop="doCommitAction">
                <span>确</span><span style="margin-left: 16px">定</span>
              </el-button>
            </div>

            <!-- 确定取消框 -->
            <!-- <div class="inner_dialog"></div> -->
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "../../components/CommonDialog";
export default {
  data() {
    return {
      //是否展示试听课选项
      isShowTryClass: true,
      //宽度
      width: "680px",
      //是否显示确定按钮加载框的标识
      showCommitLoading: false,
      //显示加载框
      showLoading: false,
      courseData: [],
      //班级名称
      className: "",
      //手机号
      phoneNumber: "",
      //校区名或编码
      schoolName: "",
      //权限集合
      CourseList: [],
      //老师
      teacherlist: [],
      //教师姓名
      teacherName: "",
      //预招生人数
      classNumber: null,
      //限制人数
      isLimPeople: false,
      //权限json
      levelInfo: "",
      //权限类型（0试用 1正式）
      modeType: 0,
    };
  },
  created() {},
  mounted() {
    //获取课程课授权的权限
    this.getCourseList();
    //获取老师列表
    this.getTeacherList();
  },
  methods: {
    //选择类型
    selectType(type) {
      this.modeType = type;
      this.getCourseList();
    },
    //获取课程课授权的权限
    getCourseList() {
      let param = this.getHttpParams();
      param.type = 2;
      param.mode = this.modeType;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          let resulte = res.data;
          if (resulte.state == 200) {
            //请求成功
            this.CourseList = resulte.data;
            if (this.CourseList.length == 0 && this.modeType == 0) {
              this.isShowTryClass = false;
              this.modeType = 1;
              this.getCourseList();
            } else {
              // this.isShowTryClass = true;
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          console.log("请求数据异常");
        });
    },
    //获取老师列表
    getTeacherList() {
      this.levelInfo = JSON.stringify(this.CourseList);

      let param = this.getHttpParams();
      param.type = 3;
      param.levelInfo = this.levelInfo;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          let resulte = res.data;
          if (resulte.state == 200) {
            //请求成功
            console.log(resulte);
            this.teacherName = "";
            this.teacherlist = resulte.data;

            for (let i = 0; i < this.teacherlist.length; i++) {
          
                if(this.teacherlist[i].state ==-1){

                  this.teacherlist[i].teacher_name = this.teacherlist[i].teacher_name + "(班级已满)"
                  
                }
            }

          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          console.log("请求数据异常");
        });
    },
    //选中科目 状态（-1余额不足，0未开启，1存在已开启级别）
    selectCourse(index) {
      // console.log("选中科目" + index);
      if (this.CourseList[index].state == 0) {
        this.CourseList[index].state = 1;

        // this.CourseList[index].levelList.forEach((element) => {
        //   element.state = 1;
        // });
        this.CourseList[index].levelList[0].state = 1;
      } else if (this.CourseList[index].state == 1) {
        var temp = false;
        this.CourseList[index].levelList.forEach((element) => {
          if (element.state == 0) {
            element.state = 1;
            temp = true;
          }
        });
        if (!temp) {
          this.CourseList[index].state = 0;
          this.CourseList[index].levelList.forEach((element) => {
            element.state = 0;
          });
        }
      }
      //获取老师列表
      this.getTeacherList();
    },
    //选中级别 状态（-1余额不足，0未开启，1存在已开启级别）
    selectCourseItem(index, k) {
      event.stopPropagation();
      // console.log("选中级别" + index + "k=" + k);
      if (this.CourseList[index].levelList[k].state == 0) {
        this.CourseList[index].levelList[k].state = 1;
        this.CourseList[index].state = 1;
      } else if (this.CourseList[index].levelList[k].state == 1) {
        this.CourseList[index].levelList[k].state = 0;

        var isSelect = true;
        this.CourseList[index].levelList.forEach((element) => {
          if (element.state == 1) {
            isSelect = false;
          }
        });

        if (isSelect) {
          this.CourseList[index].state = 0;
        }
      }

      //获取老师列表
      this.getTeacherList();
    },

    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },
    //确定的操作
    doCommitAction() {
      this.showCommitLoading = true;
      this.httpUpload();
    },

    //上传接口
    httpUpload() {
      if (this.isEmpty(this.className)) {
        this.showWarnMsg("班级名称不能为空");
        this.showCommitLoading = false;
        return;
      }
      if (this.isEmpty(this.teacherName)) {
        this.showWarnMsg("请选择有课程访问权限的老师");
        this.showCommitLoading = false;
        return;
      }

      if (this.isLimPeople && this.isEmpty(this.classNumber)) {
        this.showWarnMsg("请输入预招生人数");

        this.showCommitLoading = false;
        return;
      }

      this.levelInfo = JSON.stringify(this.CourseList);

      let param = this.getHttpParams();
      param.type = 4;
      param.className = this.className;
      param.tid = this.teacherName;
      param.levelInfo = this.levelInfo;
      param.planNum = this.classNumber;
      if (this.isLimPeople) {
        param.limitPlan = "1";
      } else {
        param.limitPlan = "0";
      }
      param.mode = this.modeType;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          let resulte = res.data;
          if (resulte.state == 200) {
            //请求成功
            this.showSuccessMsg("创建成功");
            //触发父组件的关闭方法
            this.$emit("close");
            this.$emit("reflash");
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
            this.showCommitLoading = false;
          }
        })
        .catch((err) => {
          console.log("请求数据异常");
        });
    },
  },

  watch: {
    //监听输入框值的变化
    classNumber(newValue, oldValue) {
      if (newValue > 50) {
        this.classNumber = 50;
        this.showWarnMsg("预招生人数最大不能超过50人");
      }
    },
  },
  computed: {},
  components: {
    CommonDialog,
  },
  props: ["showDialog", "title"],
};
</script>
<style lang="scss" scoped>
.add_teacher {
  ::v-deep .el-dialog {
    top: 50%;
  }

  .real_content {
    .add_teacher_dialog_content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .course_list {
        margin-left: 5%;
        min-height: 400px;

        .name_input {
          margin-left: 4%;
          width: 40%;
        }
        .input {
          width: 40%;
        }

        .tips {
          margin-left: 16%;
          color: #ff0000;
        }
        .course_line {
          margin-top: -30px;
        }
        .select_list {
          margin-left: 16%;
          margin-top: -10px;
          padding-top: 10px;
          overflow-y: scroll;
          max-height: 43vh;
        }
        .item {
          min-height: 45px;
          width: 100%;
        }
        .item_.noneselect {
          background-color: #cccccc;
          padding: 5px;
          border-radius: 5px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
        .noneselect {
          background-color: #cccccc;
          padding: 8px;
          border-radius: 10px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;

          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }
        .selected {
          background-color: #f39800;
          border-radius: 10px;
          width: 120px;
          padding: 10px;
          color: #fff;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }
        .unselected {
          background-color: #ffffff;
          padding: 8px;
          border-radius: 10px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }
        .unselected:hover {
          background-color: #ffffff;
          border-radius: 10px;
          width: 120px;
          padding: 8px;
          color: #f39800;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }

        .item_selected {
          background-color: #f39800;
          border-radius: 5px;
          width: 8px;
          padding: 5px;
          color: #fff;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
        .item_unselected {
          background-color: #ffffff;
          padding: 5px;
          border-radius: 5px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
        .item_unselected:hover {
          background-color: #ffffff;
          border-radius: 5px;
          width: 120px;
          padding: 5px;
          color: #f39800;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
      }

      //底部的操作按钮
      .bom_action {
        margin-top: 20px;
        height: 48px;
        display: flex;
        justify-content: center;

        .cancel,
        .commit {
          width: 120px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
        }

        .cancel {
          background: #dcdcdc;
          margin-right: 30px;
          color: black;
        }

        .commit {
          background: #169bd5;
          // background: #70B600;
          color: white;
        }
      }

      //操作框
      .inner_dialog {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.4);
      }
    }
  }
}
</style>
