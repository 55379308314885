//班级管理
<template>
  <div class="class_manager">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <!-- 内容部分 -->
        <div class="class_manager_content">
          <!-- 顶部的操作框部分 -->
          <div class="top_info margin_top_10">
            <!-- 新增班级 -1游客 0学生  1老师 2校区教务 3校长 4管理员 5超级管理员-->
            <div class="com_bt_action search_bt_active margin_left_14" @click.stop="addClass" v-if="role == '3'">新建班级</div>
            <!-- 教师 -->
            <div v-if="role == '3'" class="margin_left_14">教师：</div>

            <!-- 教师或者班级ID -->
            <el-input v-if="role == '3'" clearable v-model="teacherValue" placeholder="请输入教师姓名或手机号"></el-input>
            <!-- 班级 -->
            <div class="margin_left_14">班级：</div>

            <!-- 班级名称或者班级ID -->
            <el-input clearable v-model="classValue" placeholder="请输入班级名称或者ID"></el-input>
          </div>
          <div v-show="!isShowEmptyView">
            <!-- 表格部分 -->
            <div class="dv_table_view_content">
              <el-table style="width: 98%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
                <!-- 班级名称 -->
                <el-table-column :show-overflow-tooltip="true" prop="class_name" label="班级名称" width="120"></el-table-column>

                <!-- 班级ID  -->
                <el-table-column prop="class_id" :show-overflow-tooltip="true" label="班级ID" width="100"></el-table-column>

                <!--班级类型（1正常班级 2试听班级）-->
                <el-table-column prop="class_id" label="班级类型" width="90">
                  <template slot-scope="scope">
                    {{ scope.row.type == 1 ? "正式班" : "试听班" }}
                  </template>
                </el-table-column>
                <!-- 所属老师  -->
                <el-table-column :show-overflow-tooltip="true" prop="teacher_name" label="所属老师" width="90"></el-table-column>

                <!-- 教师手机号  -->
                <el-table-column prop="tel" label="教师手机号" width="120"></el-table-column>
                <!-- 开设课程  -->
                <el-table-column :show-overflow-tooltip="true" prop="sub_name" label="开设课程" width="210"></el-table-column>
                <!-- 实招/预招  -->
                <el-table-column prop="class_num,plan_num" label="实招/预招" width="115">
                  <template slot-scope="scope"> {{ scope.row.class_num }}/{{ scope.row.plan_num }} </template>
                </el-table-column>
                <!-- 限人数  -->
                <el-table-column prop="classNum" label="限人数" width="115">
                  <template slot-scope="scope">
                    {{ scope.row.limit_plan == 1 ? "是" : "否" }}
                  </template>
                </el-table-column>
                <!-- 已上/已排  -->
                <el-table-column prop="attend_num,arrange_num" label="已上/已排" width="115">
                  <template slot-scope="scope"> {{ scope.row.attend_num }}/{{ scope.row.arrange_num }} </template>
                </el-table-column>

                <!--  操作 -->
                <el-table-column label="操作" min-width="15%">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        :disabled="scope.row.attend_num == 0 && scope.row.arrange_num == 0"
                        @click="doEnterEvaluation(scope.row)"
                        type="text"
                        size="small"
                        >考勤与评价</el-button
                      >
                      <el-button @click="doLookAction(scope.row)" type="text" size="small">查看</el-button>
                      <el-button v-if="role == '3'" @click="doCourseArrangement(scope.row)" type="text" size="small">排课</el-button>

                      <!-- 更多 -->
                      <el-popover placement="bottom-start" width="50px" trigger="click" :ref="`popover-${scope.$index}`">
                        <div style="margin-left: 5%" v-if="role == '3'">
                          <el-button type="text" size="small" @click="doReplaceTeacher(scope)"> 换老师</el-button>
                        </div>
                        <div style="margin-left: 5%">
                          <el-button type="text" size="small" @click="doEdit(scope)"> 编辑</el-button>
                        </div>
                        <div style="margin-left: 5%">
                          <el-button type="text" size="small" @click="doCopy(scope)" v-if="scope.row.studentPlat == 1"> 复制邀请码</el-button>
                        </div>
                        <div style="margin-left: 5%">
                          <el-button type="text" size="small" @click="doDel(scope)"> 删除</el-button>
                        </div>

                        <el-button style="margin-left: 10px" type="text" size="small" slot="reference"> 更多</el-button>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

              <!-- 顶部的分页器部分 -->
              <div class="bom_page margin_bom_20" v-if="tableData && tableData.length > 0">
                <el-pagination
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currPage"
                  :page-sizes="pageSizeArr"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalSize"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <!-- 空视图部分 -->
          <div class="show_content" v-show="isShowEmptyView">
            <EmptyView></EmptyView>
          </div>

          <!-- 替换老师的dialog -->
          <ReplaceTeacherDialog
            v-if="isShowReplaceTeacherDialog"
            @close="isShowReplaceTeacherDialog = false"
            :isCenter="isCenter"
            :showDialog="isShowReplaceTeacherDialog"
            :width="repalceTeacherDialogWidth"
            title="提示"
            :item="Item"
            @updatePage="httpData"
          ></ReplaceTeacherDialog>

          <!-- 删除班级的弹框 -->
          <RemoveClassDialog
            ref="del_item"
            v-if="showDelDialog"
            :showDialog="showDelDialog"
            @close="showDelDialog = false"
            :title="delTitle"
            @delItem="delItem"
          ></RemoveClassDialog>

          <!-- 新增班级的弹框 -->
          <AddClassDialog
            v-if="showAddClassDialog"
            @close="closeDialog"
            :title="addTitle"
            :showDialog="showAddClassDialog"
            @reflash="httpData"
          ></AddClassDialog>

          <!-- 编辑班级 -->
          <EditClassDialog
            v-if="showEditClassrDialog"
            @close="closeDialog"
            :title="editTitle"
            :showDialog="showEditClassrDialog"
            :classItem="classItem"
            @reflash="httpData"
          ></EditClassDialog>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import Layout from "@/components/Layout.vue";
//替换老师的dialog
import ReplaceTeacherDialog from "@/components/dialog/ReplaceTeacherDialog.vue";
//移出班级的弹框
import RemoveClassDialog from "@/components/dialog/RemoveClassDialog";
//新增班级的弹框
import AddClassDialog from "@/components/dialog/AddClassDialog";
//编辑班级的弹框
import EditClassDialog from "@/components/dialog/EditClassDialog";
export default {
  data() {
    return {
      Item: null,
      classItem: null,
      //是否显示空视图的标识
      isShowEmptyView: false,
      //班级邀请码
      classCode: "",
      //编辑班级
      showEditClassrDialog: false,
      //新增班级
      showAddClassDialog: false,
      //删除班级
      showDelDialog: false,
      //是否显示替换老师的弹框
      isShowReplaceTeacherDialog: false,

      showLoading: -1,

      //弹框标题是居中显示
      isCenter: true,

      //查看班级dialog的宽度
      dialogWidth: "680px",

      //替换老师弹框的宽度
      repalceTeacherDialogWidth: "520px",

      //搜索框的值
      teacherValue: "",

      classValue: "",

      //搜索按钮是否高亮
      isArrowSearch: false,

      //表格的最大高度
      maxHeight: 0,

      //总的数据条数
      totalSize: 30,

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //禁用标题
      delTitle: "删除班级",

      //标题
      addTitle: "新增班级",

      editTitle: "编辑班级",
      //表格数据
      tableData: [],
      //角色
      role: "",
    };
  },
  created() {
    //初始化数据
    this.initData();
  },
  mounted() {
    this.httpData();
  },
  methods: {
    //初始化表格数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - -54 - 20 - 68 - 120;

      //-1游客 0学生  1老师 2校区教务 3校长 4管理员 5超级管理员
      this.role = this.getUserInfo().role;
      console.log(this.role);
    },
    //关闭新增教师弹框
    closeDialog() {
      this.showAddClassDialog = false;
      this.showEditClassrDialog = false;
    },
    //新增班级
    addClass() {
      this.showAddClassDialog = true;
    },
    //搜索的方法
    search() {
      if (this.isArrowSearch) {
        //先还原页码到第一页
        this.currPage = 1;
        this.httpData();
      }
    },

    //查看班级详情
    doLookAction(item) {
      //跳转到查看课程界面
      this.$router.push({
        path: "/Home/ClassDetail",
        query: {
          item: JSON.stringify(item),
        },
      });
    },

    //考勤与评价
    doEnterEvaluation(item) {
      //跳转到考勤与评价界面
      this.$router.push({
        path: "/Home/ClassEvaluationAndAttendance",
        query: {
          item: JSON.stringify(item),
        },
      });
    },

    //排课
    doCourseArrangement(item) {
      //跳转到查看课程界面
      this.$router.push({
        path: "/Home/ManagerCourse",
        query: { item: item },
      });
    },

    //换老师
    doReplaceTeacher(scope) {
      console.log(scope.row);
      this.Item = scope.row;
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      //打开替换老师的弹框
      this.isShowReplaceTeacherDialog = true;
    },
    //编辑班级
    doEdit(scope) {
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      this.classItem = scope.row;
      this.showEditClassrDialog = true;
    },
    //复制邀请码
    doCopy(scope) {
      console.log(scope.row);
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      this.classCode = scope.row.code;
      var temp = document.createElement("textarea");
      temp.value = this.classCode;
      document.body.appendChild(temp);
      temp.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      temp.style.display = "none";
      this.showSuccessMsg("已复制");
    },
    //删除班级
    doDel(scope) {
      console.log(scope.row);
      scope._self.$refs[`popover-${scope.$index}`].doClose();
      this.del_classID = scope.row.class_id;
      this.showDelDialog = true;
    },
    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //重新刷新数据
      this.httpData();
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      // console.log("当前页码是:", this.currPage);
      //重新刷新数据
      this.httpData();
    },

    /**
     *  班级列表接口
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 1;
      param.page = this.currPage - 1;
      param.pagecount = this.pageSize;

      //老师姓名或电话
      if (!this.isEmpty(this.teacherValue)) {
        param.tel = this.teacherValue;
      } else {
        param.tel = "";
      }

      //班级名称或ID
      if (!this.isEmpty(this.classValue)) {
        param.cid = this.classValue;
      } else {
        param.cid = "";
      }
      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取总条数
            this.totalSize = Number.parseInt(resulte.data.totalNum);

            if (this.totalSize != 0) {
              //证明有数据
              this.isShowEmptyView = false;

              //获取列表数据
              this.tableData = resulte.data.infoList;
            } else {
              //显示空视图
              this.isShowEmptyView = true;
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //删除班级接口
    delItem() {
      let param = this.getHttpParams();
      param.type = 7;
      param.cid = this.del_classID;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then((res) => {
          let resulte = res.data;
          if (resulte.state == 200) {
            //请求成功
            console.log(resulte);
            this.showSuccessMsg("操作成功");

            //重新刷新数据
            this.httpData();
            //关闭弹框
            this.showDelDialog = false;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          console.log("请求数据异常");
        });
    },
  },
  computed: {},
  components: {
    Layout,
    ReplaceTeacherDialog,
    AddClassDialog,
    EditClassDialog,
    RemoveClassDialog,
  },
  watch: {
    //班级值
    classValue(newValue, oldValue) {
      if (newValue != oldValue) {
        this.currPage = 1;
        this.httpData();
      }
    },

    //教师值
    teacherValue(newValue, oldValue) {
      if (newValue != oldValue) {
        this.currPage = 1;
        this.httpData();
      }
    },
  },
};
</script>

<style lang="scss">
.el-popover {
  position: absolute;
  background: #fff;
  min-width: 50px;

  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  word-break: break-all;
}
</style>
<style lang="scss" scoped>
.class_manager {
  height: calc(100vh - 77px);

  //内容部分
  .class_manager_content {
    position: relative;
    margin: 0 4px;
    height: 100%;
    display: flex;
    flex-direction: column;

    ::v-deep .el-table .cell {
      text-align: center;
    }

    //顶部的操作栏部分
    .top_info {
      height: 54px;
      display: flex;
      align-items: center;
      min-width: $min_width;

      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }
    }
    //内容部分
    .show_content {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
    }
    //表格内容部分
    .dv_table_view_content {
      min-width: $min_width;
      margin: 10px 0;

      //底部的分页器部分
      .bom_page {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        margin-top: 18px;
        display: flex;
        align-items: center;
        height: 40px;
        justify-content: center;
      }
    }
  }
}
</style>
