var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add_teacher"},[_c('CommonDialog',{attrs:{"showDialog":_vm.showDialog,"title":_vm.title,"width":_vm.width},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"real_content"},[(_vm.showLoading)?_c('Loading'):_c('div',{staticClass:"add_teacher_dialog_content"},[_c('div',{staticClass:"course_list"},[_c('div',{staticClass:"margin_top_10"},[_c('div',[_c('span',{staticClass:"red_icon"},[_vm._v("*")]),_c('span',[_vm._v("班级名称：")]),_c('span',[_c('el-input',{staticClass:"name_input",attrs:{"clearable":"","placeholder":"请输入班级名称","maxlength":"20","size":"small"},model:{value:(_vm.className),callback:function ($$v) {_vm.className=$$v},expression:"className"}})],1)])]),_c('div',{staticClass:"margin_top_10"},[_c('span',{staticClass:"tips"},[_vm._v("注：班级类型保存后不可修改")])]),_c('div',{staticClass:"margin_top_14"},[_c('div',[_c('span',{staticClass:"red_icon"},[_vm._v("*")]),_c('span',[_vm._v("班级类型：")]),_c('span',{staticClass:"margin_left_20"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowTryClass),expression:"isShowTryClass"}],class:{
                      item_selected: _vm.modeType == 0,
                      item_unselected: _vm.modeType == 1,
                    },on:{"click":function($event){$event.stopPropagation();return _vm.selectType(0)}}},[_vm._v(" 试听课班级 ")]),_c('span',{class:{
                      item_selected: _vm.modeType == 1,
                      item_unselected: _vm.modeType == 0,
                    },on:{"click":function($event){$event.stopPropagation();return _vm.selectType(1)}}},[_vm._v(" 正式课班级 ")])])])]),_c('div',{staticClass:"item margin_top_20"},[_c('div',[_c('span',{staticClass:"red_icon"},[_vm._v("*")]),_c('span',[_vm._v("课程访问权限：")]),_c('div',{staticClass:"select_list"},_vm._l((_vm.CourseList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('span',{class:{
                        selected: _vm.CourseList[index].state == 1,
                        unselected: _vm.CourseList[index].state == 0,
                        noneselect: _vm.CourseList[index].state == -1,
                      },on:{"click":function($event){$event.stopPropagation();return _vm.selectCourse(index)}}},[_vm._v(" "+_vm._s(item.subName)+" ")]),_vm._l((_vm.CourseList[index].levelList),function(item,k){return _c('span',{key:k},[_c('span',{class:{
                          item_selected: _vm.CourseList[index].levelList[k].state == 1,
                          item_unselected: _vm.CourseList[index].levelList[k].state == 0,
                          item_noneselect: _vm.CourseList[index].levelList[k].state == -1,
                        },on:{"click":function($event){return _vm.selectCourseItem(index, k)}}},[_vm._v(_vm._s(item.levelName))])])})],2)}),0)])]),_c('div',{staticClass:"margin_top_10"},[_c('div',[_c('span',{staticClass:"red_icon"},[_vm._v("*")]),_c('span',[_vm._v("选择老师 ： ")]),_c('span',[_c('el-select',{staticClass:"name_input",attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.teacherName),callback:function ($$v) {_vm.teacherName=$$v},expression:"teacherName"}},_vm._l((_vm.teacherlist),function(item){return _c('el-option',{key:item.teacher_id,attrs:{"label":item.teacher_name,"value":item.teacher_id}})}),1)],1)])]),_c('div',{staticClass:"margin_top_10"},[_c('div',[_c('span',[_vm._v("预招生人数：")]),_c('span',[_c('el-input',{staticClass:"name_input",attrs:{"clearable":"","placeholder":"必须为整数","maxlength":"20","size":"small","onkeyup":"value=value.replace(/[^\\d]/g,'')"},model:{value:(_vm.classNumber),callback:function ($$v) {_vm.classNumber=_vm._n($$v)},expression:"classNumber"}})],1)])]),_c('div',{staticClass:"margin_top_10"},[_c('div',[_c('span',[_vm._v(" 限制人数：")]),_c('span',{staticClass:"margin_left_40"},[_c('el-switch',{model:{value:(_vm.isLimPeople),callback:function ($$v) {_vm.isLimPeople=$$v},expression:"isLimPeople"}})],1),_c('span',{staticClass:"margin_left_10"},[_vm._v("开启后入班人数不可超过预招人数")])])])]),_c('div',{staticClass:"bom_action"},[_c('div',{staticClass:"cancel",on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('span',[_vm._v("取")]),_c('span',{staticStyle:{"margin-left":"16px"}},[_vm._v("消")])]),_c('el-button',{class:['commit'],attrs:{"loading":_vm.showCommitLoading},on:{"click":function($event){$event.stopPropagation();return _vm.doCommitAction.apply(null, arguments)}}},[_c('span',[_vm._v("确")]),_c('span',{staticStyle:{"margin-left":"16px"}},[_vm._v("定")])])],1)])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }